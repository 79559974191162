.GalleryPlayer{
    position: fixed;
    top:0px;
    left: 0px;;
    width:100vw;
    height: 100vh;
    background-color: black;
}

.galleryImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* or a fixed height if needed */
    overflow: hidden; /* prevents any overflow */
  }
  
  .galleryImage {
    position: absolute;
    
    height: 100%; /* fills the container's height */
    width: auto;     /* maintains aspect ratio */
    transition: opacity 0.5s ease-in-out;
  }

.closeButton{
    position: fixed;
    top: 20px;
    right:50px;
    color:white;
    cursor: pointer;
    z-index: 3;
}

/* When fading out */
.fadeOut {
    opacity: 0;
  }
  
  /* When fading in */
  .fadeIn {
    opacity: 1;

  }